import axios from "axios";
import store from "@/store";
import router from "@/routes/router";
import "dotenv/config";
import { applyMutationToEventStore } from "@fullcalendar/core";
import url_utils from "@/util/url";
import disconnectedNotification from "@/util/disconnectedNotification";
import imageCompression from "browser-image-compression";

const excludedTokenRoutes = ["Login", "Logout", null];

axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";

const axios_instance = axios.create({
  baseURL: url_utils.getBaseUrl("/api/v1"),
  xsrfHeaderName: "X-CSRFTOKEN",
  xsrfCookieName: "csrftoken",
});

axios_instance.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios_instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Auth based error handler
function handleError(err) {
  console.log(err);
  if (err.code === "ERR_NETWORK") {
    disconnectedNotification.send();
  }
  if (err.response) {
    console.log(err.response.data);
    if (err.response.status === 408 || err.code === "ECONNABORTED") {
      console.log(`A timeout happend on url ${error.config.url}`);
      // notify({
      //     message: 'Connection timed out.',
      //     type: 'danger',
      //     timeout: 5000,
      //     horizontalAlign: 'center',
      //     verticalAlign: 'bottom'
      // }
      // )
    } else if (err.code === "ERR_NETWORK") {
      disconnectedNotification.send();
    } else if (err.response.status === 502) {
      if (!excludedTokenRoutes.includes(router.currentRoute.name)) {
        router.push({ path: "logout" });
      }
    } else if (err.response.status === 401) {
      console.log(`User is unauthenticated: ${err.config.url}`);
      if (!excludedTokenRoutes.includes(router.currentRoute.name)) {
        router.push({ path: "logout" });
      }
    } else if ([503, 521, 522, 523, 524].includes(err.response.status)) {
      console.log(`Failed to reach server: ${err.config.url}`);
      store.store.commit("logout");
      if (!excludedTokenRoutes.includes(router.currentRoute.name)) {
        router.push({ path: "logout" });
      }
    } else {
      console.log("request");
      console.log(err.request);
    }
  } else if (err.request) {
    console.log("request");
    console.log(err.request);
  } else if (err.message) {
    console.log(err.message);
  }
}

export default {
  upload_file(file) {
    let data = new FormData();
    // data.append("file_uploaded", file);
    data.append("file", file);
    return this.axios_instance
      .post("/property/upload/", data, {
        timeout: 10000,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log(response);
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data);
          throw err.response.data;
        } else if (err.request) {
          console.log("request");
          console.log(err.request);
        } else if (err.message) {
          console.log(err.message);
        }
        handleError(err);
        raise;
      });
  },
  upload_listing_image(file, listing_id) {
    // Define the options for compression
    const options = {
      maxSizeMB: 0.2, // Maximum size in MB (200KB)
      maxWidthOrHeight: 1920, // Optional: Max width or height of the image
      useWebWorker: true, // Use web workers for faster compression
    };

    return imageCompression(file, options)
      .then((compressedFile) => {
        let data = new FormData();
        data.append("file", compressedFile); // Use the compressed file
        data.append("listing", listing_id);

        return this.axios_instance.post("/property/upload/", data, {
          timeout: 10000,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data);
          throw err.response.data;
        } else if (err.request) {
          console.log("request");
          console.log(err.request);
        } else if (err.message) {
          console.log(err.message);
        }
        handleError(err);
        raise;
      });
  },
  update(data) {
    //supports the relevant information in question.
    /*
        {
            first_name: "", 
            last_name: "", 
            email: "",
        }
        */
    return this.axios_instance
      .put("/users/me/", data, { timeout: 3000 })
      .then((res) => {
        return this.get();
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data);
          throw err.response.data;
        } else if (err.request) {
          console.log("request");
          console.log(err.request);
        } else if (err.message) {
          console.log(err.message);
        }
        handleError(err);
        raise;
      });
  },
};
