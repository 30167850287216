import Vue from "vue";
import DashboardPlugin from "./plugins/dashboard-plugin";
import App from "./App.vue";
import store from "@/store";
import { Lock } from "./util/locking";
import rest_api from "@/rest_api";
import user_util from "@/util/user";

// Userback
import UserbackPlugin from "@userback/vue";

// router setup
import router from "./routes/router";

//Vue Apollo
import VueApollo from "vue-apollo";
import { apolloClient } from "@/apollo_init";
import { apolloClientUnAuth } from "@/apollo_init_unauth";

// Google Analytics
import VueGtag from "vue-gtag";

// Sentry
import * as Sentry from "@sentry/vue";

//utils
import url_utils from "@/util/url";
import graph_utils from "@/util/graph";
import general_utils from "@/util/general";
import { GlobalEventEmitter } from "@/util/GlobalEventEmitter";

window.utils = general_utils;
window.user_util = user_util;
window.global_event_emitter = GlobalEventEmitter;
window.rest_api = rest_api;
window.graph_utils = graph_utils;

// plugin setup
Vue.use(DashboardPlugin);

// Initializing store on window
window.store = store.store;

// Google analytics setup
Vue.use(VueGtag, {
  config: { id: "G-SLM9VZ3J48" },
});

// Sentry
if (process.env.NODE_ENV !== "development"){
  Sentry.init({
    Vue,
    dsn: "https://7ddea38b7fd745b7842f8c17f96827e7@o4505420590678016.ingest.sentry.io/4505420644024320",
    integrations: [
        new Sentry.BrowserTracing({
            // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [
                "localhost",
                /^https:\/\/platform\.investrand\.co\.za\/api/,
            ],
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
}


/* Vue Apollo Setup */
Vue.use(VueApollo);

const apolloProvider = new VueApollo({
  clients: {
    apolloClient,
    apolloClientUnAuth,
  },
  defaultClient: apolloClient,
  errorHandler(err) {
    utils.handle_apollo_err(err);
  },
});

// Userback
Vue.use(UserbackPlugin, { token: "A-KsNLLLVHhqXCagqIjGwzMNCot" });

window.refreshTokenLock = new Lock();

/* eslint-disable no-new */
new Vue({
  el: "#app",
  render: (h) => h(App),
  router,
  apolloProvider,
  store: store.store,
  gen_utils: general_utils,
});

let excludedRoutes = ["Login", "Logout", null];

window.handleActivity = function handleActivity() {
  if (!store.store.getters.isActive && store.store.getters.isLogin) {
    store.store.commit("setIsActive", true);
  } else if (!store.store.getters.isLogin) {
    rest_api.user.logout();
  }
  window.removeEventListener("keyup", handleActivity);
  window.removeEventListener("mousemove", handleActivity);
};

function listenForActivity() {
  let last_login_minutes = 1000;
  if (store.store.getters.getLastTokenRefresh != "") {
    last_login_minutes =
      (new Date() - new Date(store.store.getters.getLastTokenRefresh)) /
      1000 /
      60;
  }

  if (store.store.getters.isLogin && last_login_minutes < 9) {
    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keyup", handleActivity);
  } else {
    if (!excludedRoutes.includes(router.currentRoute.name)) {
      rest_api.user.logout();
    }
  }
}

function refresh_token() {
  if (
    store.store.getters.isActive &&
    store.store.getters.isLogin &&
    !excludedRoutes.includes(router.currentRoute.name)
  ) {
    rest_api.user.refreshToken();
    store.store.commit("setIsActive", false);
    listenForActivity();
  }
  setTimeout(refresh_token, 1000 * 60 * 10); /* 10 MINUTES */
  //   setTimeout(refresh_token, 1000 * 60 ); /* 1 MINUTE */
}

// Disabling token refresh.
// listenForActivity();
// refresh_token();
