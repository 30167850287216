export default {
getBaseUrl(api_suffix) {
        var base_url = `${window.location.origin.replace(
            "/p",
            "/api-p"
        )}`;
        if (process.env.NODE_ENV === "development") {
            base_url = `http://127.0.0.1:8000`;
        } else if (window.location.origin.includes("dev-platform")) {
            base_url = `${window.location.origin.replace(
                "dev-",
                "dev-api-"
            )}`;
        }else if (window.location.origin.includes("uat-platform")) {
            base_url = `${window.location.origin.replace(
                "uat-",
                "uat-api-"
            )}`;
        }
        if (
            !base_url.includes("https") &&
            process.env.NODE_ENV !== "development"
        ) {
            base_url = base_url.replace("http", "https");
        }
        return base_url+api_suffix;
    },
};
