export default {
    deepcopy(obj) {
        return JSON.parse(JSON.stringify(obj));
    },
    formatted_currency(value) {
        return value.toLocaleString();
    },
    deduped_array_of_objects(array, dedupe_key) {
        return [...new Set(array.map((obj) => obj[dedupe_key]))].map((id) => {
            return array.find((obj) => obj[dedupe_key] === id);
        });
    },
};
