var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-button',{class:[
        { 'rounded-circle': _vm.round },
        { 'btn-wd': _vm.wide },
        { 'btn-icon btn-fab': _vm.icon },
        { 'btn-link': _vm.link },
        { disabled: _vm.disabled },
        { 'm-0': _vm.m0 },
        { 'p-0': _vm.p0 } ],attrs:{"type":_vm.nativeType,"disabled":_vm.disabled || _vm.loading,"variant":!_vm.outline ? _vm.type : ("outline-" + _vm.type),"size":_vm.size,"block":_vm.block,"pill":_vm.pill},on:{"click":_vm.handleClick}},[_vm._t("loading",function(){return [_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"fas fa-spinner fa-spin"}),_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.success),expression:"success"}],staticClass:"fas fa-check"}),_c('a',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading || _vm.success),expression:"loading || success"}]},[_vm._v(" ")])]}),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }