import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import store from "@/store";

Vue.use(VueRouter);

// configure router
const router = new VueRouter({
    routes, // short for routes: routes
    linkActiveClass: "active",
    // mode: 'history',
    scrollBehavior: (to, from, savedPosition) => {
        if (savedPosition) {
            return savedPosition;
        }
        if (to.hash) {
            return { selector: to.hash };
        }
        return { x: 0, y: 0 };
    },
});

router.beforeEach((to, from, next) => {
    let excludes = [
        "Logout",
        "Login",
        "Register",
        "ForgotPassword",
        "ResetPassword",
        "PrivacyPolicy",
        "TermsAndConditions",
        "DiscoverListingsUnAuth",
        "ViewListingUnAuth",
        "ServiceProvidersUnAuth",
        "ViewServiceProviderUnAuth",
    ];
    let logged_in = store.store.getters.isLogin;

    // Session Management
    if (!excludes.includes(to.name) && !logged_in) {
        next({ name: "Register", query: { next: to.fullPath } });
    }
    let logged_in_excluded_paged = ["Register", "Login"];
    if (logged_in_excluded_paged.includes(to.name) && logged_in) {
        next({ name: "Home" });
    }

    // Final Next
    next();
});

function handleActivity() {
    if (!store.store.getters.isActive && store.store.getters.isLogin) {
        store.store.commit("setIsActive", true);
    } else if (!store.store.getters.isLogin) {
        api.user.logout();
    }
    window.removeEventListener("keyup", handleActivity);
    window.removeEventListener("mousemove", handleActivity);
}

export default router;
