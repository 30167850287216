export default {
    // Simplify the apollo object
    apollo_to_obj_recursive(item) {
        if (typeof item !== "object") {
            return item;
        }

        if (item !== null && item.edges !== undefined) {
            let tmp = [];
            for (let edge of item.edges) {
                tmp.push(this.apollo_to_obj_recursive(edge));
            }
            return tmp;
        } else if (item !== null && item.node !== undefined) {
            return this.apollo_to_obj_recursive(item.node);
        } else if (item !== null && item !== undefined) {
            let new_obj = {};
            for (let [key, value] of Object.entries(item)) {
                new_obj[key] = this.apollo_to_obj_recursive(value);
            }
            return new_obj;
        }
    },

    // Flatten a nested object
    flatten_objects_recursive(obj) {
        if (Array.isArray(obj)) {
            let new_arr = [];
            for (let item of obj) {
                new_arr.push(this.flatten_objects_recursive(item));
            }
            return new_arr;
        } else if (typeof obj == "object") {
            let new_obj = {};
            for (let [key, value] of Object.entries(obj)) {
                let condensed = this.flatten_objects_recursive(value);
                if (typeof condensed == "object" && !Array.isArray(condensed)) {
                    for (let [_key, _value] of Object.entries(condensed)) {
                        condensed[`${key}__${_key}`] = _value;
                        delete condensed[_key]
                    }
                    
                    new_obj = Object.assign(new_obj, condensed);
                } else {
                    new_obj[key] = condensed;
                }
            }
            return new_obj;
        }
        return obj;
    },

    handle_apollo_err(err) {
        for (let [key, value] of Object.entries(err)) {
            switch (key) {
                case "graphQLErrors":  // Exceptions thrown in mutations provide explicit errors
                    console.log(value)
                    for (let msg of value) {
                        utils.toast.error(msg.message)
                    }
                    break;
                case "networkError": // Handle by status code. Unauthorized is logged out 
                    console.log(value)
                    if (!(!!value)) {
                        break;
                    }
                    switch (value.statusCode) {
                        case 401:
                            utils.toast.error("Unauthorised, please log in")
                            api.user.logout()
                            break;
                        case 403:
                            utils.toast.error("Forbidden, you may not have permissions to perform this action")
                            break;
                        case 404:
                            utils.toast.error("Request not successful, endpoint not found")
                            break;
                        case 413:
                            utils.toast.error("Payload too large, please attempt again with a smaller payload")
                            break;
                        case 418:
                            utils.toast.error("The server refuses the attempt to brew coffee with a teapot")
                            break;
                        case 522:
                            utils.toast.error("Request timed out")
                            api.user.logout()
                            break;
                        default:
                            utils.toast.error("Error: Something went wrong, please try again.")
                            break;
                    }
                    break;
            }
        }
    },

    flatten_relay_response(obj){
        return this.flatten_objects_recursive(
            this.apollo_to_obj_recursive(obj)
        );
    }
};
